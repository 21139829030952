import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";
import { formatText } from "../utils/utils";

const color = "amber-500";

const BenefitSection = ({ title, description, highlightedText, imgName }) => (
  <div className="max-w-7xl mx-auto relative mt-16 sm:mt-20">
    <div className="relative max-w-7xl mx-auto px-4 pb-12 md:py-20 sm:px-6 lg:px-8 lg:py-32">
      <div className="md:w-1/2 md:pr-10 lg:pr-28">
        {description[0] && (
          <p
            className="text-gray-500 text-xl tracking-tight"
            dangerouslySetInnerHTML={{
              __html: formatText(description[0], color),
            }}
          />
        )}
        {highlightedText && (
          <p className="mt-2 text-center text-gray-500 text-2xl font-bold">
            {highlightedText}
          </p>
        )}
        {description[1] && (
          <p
            className="mt-2 text-gray-500 text-xl tracking-tight"
            dangerouslySetInnerHTML={{
              __html: formatText(description[1], color),
            }}
          />
        )}
      </div>
    </div>
    <div className="h-full md:absolute md:top-0 md:right-0 md:h-full md:w-1/2">
      <GatsbyImage
        image={useImageForData(imgName)}
        className="w-full h-full object-cover rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
        imgClassName="w-full h-full object-cover rounded-xl shadow-xl ring-1 ring-black ring-opacity-5"
        alt={imgName}
      />
    </div>
  </div>
);

export default BenefitSection;
