import React from "react";
import { formatText } from "../utils/utils";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImageForData } from "../../hooks/useAllImages";

const firstColor = "gray-500";
const secondColor = "amber-500";

const DifferentSection = ({
  backgroundImgName,
  firstTitle,
  firstDescription,
  firstImgName,
  secondTitle,
  secondDescription,
  secondImgName,
}) => (
  <div className="max-w-7xl mx-auto relative mt-16 sm:mt-20">
    <div className="relative">
      <div className="absolute md:w-1/2 inset-0">
        <GatsbyImage
          image={useImageForData(backgroundImgName)}
          className="w-full h-full object-cover"
          imgClassName="w-full h-full object-cover"
          alt={firstTitle + secondTitle}
        />
        <div
          className="absolute inset-0 bg-gray-50 bg-opacity-80 mix-blend-lighten"
          aria-hidden="true"
        />
      </div>
      <div className="relative mx-auto py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
        <h2 className="text-sm leading-8 grid grid-cols-2 sm:grid-cols-2 text-center font-extrabold tracking-tight sm:text-3xl">
          <span className="text-right text-[#06293d]">{firstTitle}</span>
          <span className="text-left text-amber-500">{secondTitle}</span>
        </h2>
        <div className="mt-4 sm:mt-8 grid md:grid-cols-2 gap-8 text-lg tracking-tight">
          {/* first column */}
          <div>
            <GatsbyImage
              image={useImageForData(firstImgName)}
              className="block mx-auto w-1/2 object-cover"
              imgClassName="block mx-auto w-1/2 object-cover"
              alt={firstTitle}
            />
            {/* description */}
            <div className="mt-4 sm:mt-8 flex flex-col gap-2 sm:gap-4 max-w-lg">
              {firstDescription.map((par) => (
                <p
                  className="text-gray-500"
                  dangerouslySetInnerHTML={{
                    __html: formatText(par, firstColor),
                  }}
                />
              ))}
            </div>
          </div>
          {/* second column */}
          <div>
            <GatsbyImage
              image={useImageForData(secondImgName)}
              className="block mx-auto object-cover"
              imgClassName="block mx-auto object-cover"
              alt={secondTitle}
            />
            {/* description */}
            <div className="mt-4 sm:mt-8 flex flex-col gap-2 sm:gap-4 max-w-lg">
              {secondDescription.map((par) => (
                <p
                  className="text-gray-500"
                  dangerouslySetInnerHTML={{
                    __html: formatText(par, secondColor),
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default DifferentSection;
