import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { useImageForData } from "../../hooks/useAllImages";

const NewFeaturesSection = ({ title, description, children }) => (
  <div className="max-w-7xl mx-auto relative mt-16 sm:mt-20 px-4 sm:px-6 lg:px-8">
    <div className="lg:text-center">
      <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-[#06293d] sm:text-4xl">
        {title}
      </h2>
      {description && (
        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          {description}
        </p>
      )}
    </div>

    <div className="mt-10">
      <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
        {children}
      </dl>
    </div>
  </div>
);
export default NewFeaturesSection;
