import React from "react";
import { formatText } from "../utils/utils";

const color = "amber-500";

const NewFeatureItem = ({ Icon, name, description }) => (
  <div key={name} className="relative">
    <dt>
      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-amber-500 text-white">
        <Icon className="h-6 w-6" aria-hidden="true" />
      </div>
      {name && (
        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
          {name}
        </p>
      )}
    </dt>
    <dd
      className="mt-2 ml-16 text-base text-gray-500"
      dangerouslySetInnerHTML={{
        __html: formatText(description, color),
      }}
    />
  </div>
);

export default NewFeatureItem;
