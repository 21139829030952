import * as React from "react";

import PageTitle from "../../components/elements/PageTitle";
import Header from "../../components/sections/Header";
import Footer from "../../components/sections/Footer";
import BenefitSection from "../../components/sections/BenefitSection";
import Title from "../../components/elements/Title";
import DifferentSection from "../../components/sections/DifferentSection";
import NewFeaturesSection from "../../components/sections/NewFeaturesSection";
import NewFeatureItem from "../../components/elements/NewFeatureItem";
import {
  SaveAsIcon,
  StatusOfflineIcon,
  SwitchHorizontalIcon,
  WifiIcon,
} from "@heroicons/react/solid";
import KnowMoreSection from "../../components/sections/KnowMoreSection";

const BufferingUnitEnPage = () => (
  <div className="bg-white">
    <PageTitle lang={"en"} pageTitle={"Buffering Unit"} description={""} />
    <Header lang={"en"} />
    <Title title={"Buffering Unit"} />
    <BenefitSection
      title="*THE* *NEXT* *GENERATION* *OF* CONTROL UNITS"
      description={[
        "We implemented a *NEW* *WAY* to process fiscal data, the latest generation of hardware that signs, stores and *REPLICATES* fiscal data to the server and can be connected to the local network and support *MULTIPLE* cash registers",
      ]}
      /* highlightedText="plus"*/
      imgName="close-up-baker.jpg"
    />
    <DifferentSection
      firstTitle="CONTROL UNIT V"
      firstDescription={[
        "Fiscal data is stored *ONLY* on the Control Unit, and *THE* *TAX* *AGENCY* *NEEDS* *TO* *VISIT* *YOUR* *POINT* *OF* *SALE* to download fiscal data",
        "The solution *WILL* *BECOME* *OBSOLETE* soon",
      ]}
      firstImgName="cu_schema.png"
      secondTitle="S BUFFERING UNIT"
      secondDescription={[
        "Fiscal data is being sent to the server in *REAL* *TIME*",
        "There is *NO* more *NEED* for the Tax Agency *TO* *VISIT* *YOUR* *POINT* *OF* *SALE:* they just collect data from the Control Server",
        "All fiscal data is replicated and kept on *BOTH* *SIDES:* Buffering Unit and Control Server",
        "If the internet connection is lost, the Buffering Unit *CONTINUES* *SIGNING* *AND* *KEEPING* fiscal data, and waits until the internet connection is restored, then sends data to the Control Server",
      ]}
      secondImgName="bu_schema.png"
    />
    <NewFeaturesSection title="New features" /*description="description"*/>
      <NewFeatureItem
        Icon={WifiIcon}
        name="All to one"
        description="Buffering unit can operate MULTIPLE cash registers “out of box” - just PLUG it into the socket, connect it to the local WI-FI and start working"
      />
      <NewFeatureItem
        Icon={SwitchHorizontalIcon}
        name="Easy to start"
        description="Just REPLACE old Control Unit with Buffering Unit. No extra configuration is needed"
      />
      <NewFeatureItem
        Icon={SaveAsIcon}
        name="Large memory"
        description="We inserted an SD-card that will store fiscal data AT LEAST 5 YEARS"
      />
      <NewFeatureItem
        Icon={StatusOfflineIcon}
        name="Buffering"
        description="Buffering unit can be OFFLINE FOR 48 HOURS without harm to your business"
      />
    </NewFeaturesSection>
    <KnowMoreSection
      /*title="To know more"*/
      description="to know more..."
      linkTitle="Contact us"
      linkPath="/en/contacts"
    />
    <Footer lang={"en"} />
  </div>
);

export default BufferingUnitEnPage;
