import { Link } from "gatsby";
import React from "react";

const KnowMoreSection = ({ title, description, linkTitle, linkPath }) => (
  <div className="mx-auto mt-12">
    <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 flex flex-col sm:flex-row items-center justify-center gap-4 sm:gap-10">
      <h2 className="text-3xl font-extrabold tracking-tight text-[#06293d] md:text-4xl grid gap-4">
        {title && <span className="block">{title}</span>}
        {description && <p className="block font-medium text-lg text-gray-500">
          {description}
        </p>}
      </h2>
      <div className="flex">
        <div className="inline-flex rounded-md shadow">
          <Link
            to={linkPath}
            className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600"
          >
            {linkTitle}
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default KnowMoreSection;
